import React from 'react'

import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  SimpleGrid,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'

import BackgroundSVG from '../images/undraw_Stability_ball_.inline.svg'
import offersImage from '../images/undraw_Cloud_docs_re_xjht.svg'
import clockImage from '../images/waiting.svg'

import {
  Layout,
  SEO,
  Wrapper,
  Headline,
  ContactForm,
  ImageGallery,
} from '../components'

const IndexPage = () => (
  <Box
    overflowX={['hidden', 'hidden']}
    position="relative"
  >
    <Box
      pos="absolute"
      top="5vw"
      left={['10vw', null, '40vw']}
      width="110vw"
      maxWidth="700px"
      zIndex="-1"
    >
      <BackgroundSVG style={{ maxWidth: '100%' }} />
    </Box>
    <Layout>
      <SEO
        title="Physiotherapie aus Wolgast | Physio Praxis Nord"
        lang="de"
      />
      <Box
        as="h1"
        // fontSize={['3rem', '4.75rem', '6.75rem']}
        fontSize={['calc(0.5rem + 9vw)', null, '5.7rem']}
        fontWeight="extrabold"
        mb="10"
        lineHeight="none"
      >
        <Box>Physiotherapie</Box>
        Praxis{' '}
        <Box
          as="strong"
          fontWeight="normal"
        >
          in Wolgast
        </Box>
      </Box>
      <Text
        variant="muted"
        textStyle="hero"
        maxW="600px"
        color="gray.500"
      >
        Wir freuen uns über Ihr Interesse an unserer Praxis und möchten uns kurz
        vorstellen.
      </Text>

      <Wrapper mt={['200px', null, '100px']}>
        {/* <Alert>
          <Box as="h2" textStyle="h2" pb="4">
            Hinweis zur Corona-Krise
          </Box>
          <Text textStyle="body">
            Auch in der Corona-Krise sind wir für Sie da! Bitte melden Sie jeden
            Besuch an. In der gesamten Praxis herrscht Maskenpflicht.
          </Text>
        </Alert> */}
      </Wrapper>
      <Wrapper>
        <Headline>Öffnungszeiten</Headline>
        <SimpleGrid
          columns={[1, null, 2]}
          spacing="10"
        >
          <Box>
            <Table
              variant="striped"
              mb=""
            >
              <TableCaption>An Feiertagen geschlossen.</TableCaption>
              <Thead>
                <Tr>
                  <Th>Tag</Th>
                  <Th>Zeiten</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Montag</Td>
                  <Td>7:00 – 15:00 Uhr</Td>
                </Tr>
                <Tr>
                  <Td>Dienstag</Td>
                  <Td>7:00 – 15:00 Uhr</Td>
                </Tr>
                <Tr>
                  <Td>Mittwoch</Td>
                  <Td>7:00 – 15:00 Uhr</Td>
                </Tr>
                <Tr>
                  <Td>Donnerstag</Td>
                  <Td>7:00 – 15:00 Uhr</Td>
                </Tr>
                <Tr>
                  <Td>Freitag</Td>
                  <Td>7:00 – 13:00 Uhr</Td>
                </Tr>
                <Tr>
                  <Td>Samstag</Td>
                  <Td>geschlossen</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Flex
            display={['none', null, 'block']}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box
              as="img"
              marginLeft="10vw"
              maxW="100%"
              alt="physio praxis nord öffnungszeiten"
              src={clockImage}
            />
            {/* <Image /> */}
          </Flex>
        </SimpleGrid>
      </Wrapper>
      <Wrapper>
        <ImageGallery />
      </Wrapper>
      <Wrapper>
        <Headline>Leistungen</Headline>
        <SimpleGrid
          columns={[1, null, 2]}
          spacing="10"
        >
          <Box>
            <Headline as="h3">Physiotherapie</Headline>
            <UnorderedList>
              <ListItem>Manuelle Therapie</ListItem>
              <ListItem>
                Krankengymnastik auf neurophysiologischer Grundlage
                Bobath-Therapie
              </ListItem>
              <ListItem>Triggerpunkt-Therapie</ListItem>
              <ListItem>Schröpfen</ListItem>
              <ListItem>Kurzer Fuß</ListItem>
              <ListItem>Lymphdrainage</ListItem>
            </UnorderedList>
          </Box>
          <Box>
            <Headline as="h3">Wellness Anwendungen</Headline>
            <UnorderedList>
              <ListItem>Hot Stone Massage</ListItem>
              <ListItem>Kinesio Tape</ListItem>
            </UnorderedList>
          </Box>
          <Box>
            <Headline as="h3">Massage</Headline>
            <UnorderedList>
              <ListItem>Klassische Massagen</ListItem>
              <ListItem>Bindegewebsmassagen</ListItem>
              <ListItem>Massagen nach Marnitz</ListItem>
              <ListItem>Migräne-Massagen</ListItem>
              <ListItem>Colon-Massagen</ListItem>
            </UnorderedList>
          </Box>
          <Box>
            <Box
              as="img"
              maxW="100%"
              alt="physio praxis nord leistungen"
              src={offersImage}
              transform="scaleX(-1)"
            />
          </Box>
        </SimpleGrid>
      </Wrapper>
      <Wrapper>
        <Headline>Kontakt</Headline>
        <ContactForm />
      </Wrapper>
      {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to Using TypeScript</Link> */}
    </Layout>
  </Box>
)

export default IndexPage
